<template>

    <div class="h-full w-full flex flex-row">

        <div class="overflow-hidden h-full w-3/5">

            <loader :loading="loading" />

            <chart-gauge :data="[sales.won_with_next_to_win_objective, sales.won_objective]" :limit="sales.won_with_next_to_win_with_estimated_objective">

                <div class="flex flex-col text-center ">

                    <div class="text-4xl mt-8 font-semibold mb-4 text-blue">
                        {{ sales.won_with_next_to_win_amount | reduceBigNumbers(2) }} €
                    </div>

                    <div class="flex mb-6 w-1/2  mx-auto px-4 flex-row">
                        <div class="w-full overflow-hidden">
                            <div class="text-font-gray text-sm font-semiboldgit a">% Obj</div>
                            <div class="text-xl font-semibold" :class="successOrDangerClass(sales.won_with_next_to_win_objective,100)"> <span v-if="sales.won_with_next_to_win_objective >0">+</span> {{ sales.won_with_next_to_win_objective || 0 | numberFormat(2) }} <span v-if="isNotNaAndTbd(sales.won_with_next_to_win_objective)">%</span>
                            </div>
                        </div>
                        <div class="w-full overflow-hidden">
                            <div class="text-font-gray text-sm font-semibold">vs Avg.</div>
                            <div class="text-xl font-semibold">
                                <template v-if="sales.average_percentage > 0 "></template>
                              <span v-if="sales.average_percentage>0&&isNotNaAndTbd(sales.average_percentage)">+</span>{{ sales.average_percentage | numberFormat(2) }}<small v-if="isNotNaAndTbd(sales.average_percentage)"> ptos.</small>
                            </div>
                        </div>
                    </div>

                </div>

            </chart-gauge>

        </div>

        <div class="overflow-hidden h-full flex flex-col py-4 w-2/5">

            <loader :loading="loading" />

            <div class="text-font-gray text-lg text-center font-semibold mb-2 mt-auto">Sales Estimated</div>

            <div class="text-center text-4xl font-semibold mb-2 text-yellow">
                {{ sales.won_with_next_to_win_with_estimated_amount | reduceBigNumbers(2) }} €
            </div>

            <div v-if="sales" class="flex-1 overflow-hidden mb-auto">
                <chart-gauge :data="[null,sales.won_with_next_to_win_with_estimated_objective]" color="#FFD351">
                    <div class="text-font-gray text-sm font-semibold">% Obj</div>
                    <div class="text-xl font-semibold">
                        <span v-if="sales.won_with_next_to_win_with_estimated_objective > 0">+</span>
                        {{ sales.won_with_next_to_win_with_estimated_objective | numberFormat(2) }}
                      <span v-if="isNotNaAndTbd(sales.won_with_next_to_win_with_estimated_objective)">%</span>
                    </div>
                </chart-gauge>
            </div>

        </div>

    </div>

</template>

<script>

import { state } from '@/store';

export default {

    data() {
        return {
            salesPercent: 0,
            sales: {
                accumulated: 0,
                average: 0,
            },
            limit: 0,
            loading: false
        }
    },
    methods: {
        load() {

            this.loading = true;
            
            // if(state.rolSelected.role_type_link != 'presidencia'){
            
                this.axios.get('pipeline/sales', {params: this.params}).then(response => {
                    this.sales = response.data.data
                    this.$emit('salesData', this.sales);
                })

            // }

            this.loading = false

        },
    },
    computed: {
        params() {
            let params = state.globalParams
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        },
    },
    watch: {
        params() { this.load() }
    },
    mounted() { this.load() }
}

</script>